//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PictureResponsive from '~/components/PictureResponsive.vue';

export default {
  name: 'StackedSection',
  components: {
    PictureResponsive,
  },
  props: {
    imgUrl: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      content: {
        background: {
          mobile_img_url: this.img,
          desktop_img_url: this.img,
          default: `https://cdn3.joinfightcamp.com/c/${this.img}`,
        },
      },
    };
  },
};
